import * as React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import Seo from "@components/seo"
import Story from "@sections/Story"
import Layout from "@components/layout"
import { StoryBySlugQuery } from "@graphql"

type Props = {
    location: Location
    data: StoryBySlugQuery
}

const StoryTemplate = ({ data, location }: Props) => {
    const story = data.markdownRemark

    const title = story?.frontmatter?.title || ""
    const description = story?.frontmatter?.description || ""

    const image = story?.frontmatter?.image?.childImageSharp?.gatsbyImageData

    const meta = [
        {
            property: `og:image`,
            content: `${process.env.GATSBY_HOST}${getSrc(image)}`,
        },
    ]

    return (
        <Layout location={location}>
            <Seo title={title} description={description} meta={meta} />
            <Story data={story} />
        </Layout>
    )
}

export default StoryTemplate

export const pageQuery = graphql`
    query StoryBySlug($id: String!) {
        markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                title
                description
                date(formatString: "DD.MM.YYYY")
                videoSourceURL
                videoTitle
                image {
                    childImageSharp {
                        gatsbyImageData(layout: CONSTRAINED, breakpoints: [750, 1080, 1366, 1920])
                    }
                }
            }
        }
    }
`
