import { colors, media } from "@variables"
import styled from "styled-components"

export const Article = styled.article`
    width: 100%;

    padding: 60px 0;

    ${media.tablet} {
        padding: 100px 0;
    }
`

export const Head = styled.header`
    width: 100%;
    max-width: 800px;

    padding: 0 0 40px 0;

    ${media.tablet} {
        padding: 0 0 40px 80px;
    }

    h6 {
        color: ${colors.red};
        padding: 20px 0 0 0;
    }
`

export const Body = styled.div`
    width: 100%;
    margin: 0 auto;
    max-width: 680px;
    padding: 55px 0 0 0;

    p {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;

        ${media.tablet} {
            font-size: 24px;
            line-height: 34px;
            margin-bottom: 30px;
        }
    }
    img {
        overflow: hidden;
        border-radius: 4px;
    }
`
