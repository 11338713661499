import React from "react"
import { Container } from "./styled"

type Props = {
    videoSrcURL: string
    videoTitle: string
}

const Video = ({ videoSrcURL, videoTitle }: Props) => (
    <Container className="video">
        <iframe

            width={850}
            height={450}
            src={videoSrcURL}
            title={videoTitle}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
        />
    </Container>
)
export default Video
