import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { StoryBySlugQuery } from "@graphql"
import Container from "@components/shared/Container"
import { Article, Head, Body } from "./styled"
import Video from "@components/Video";

type Story = StoryBySlugQuery["markdownRemark"]

type Props = {
    data: Story
}

const Story = ({ data }: Props) => {
    const title = data?.frontmatter?.title || ""
    const date = data?.frontmatter?.date || ""
    const __html = data?.html || ""
    const image = data?.frontmatter?.image?.childImageSharp?.gatsbyImageData
    const videoSourceUrl = data?.frontmatter?.videoSourceURL
    const videoTitle = data?.frontmatter?.videoTitle

    return (
        <Container>
            <Article itemScope itemType="http://schema.org/Article">
                <Head>
                    <h2 itemProp="headline">{title}</h2>
                    <h6>{date}</h6>
                </Head>
                <div style={{textAlign: "center"}}>
                    <GatsbyImage image={image} alt={title} />
                </div>
                <Body
                    itemProp="articleBody"
                    dangerouslySetInnerHTML={{ __html }}
                />

                {
                    videoSourceUrl && videoTitle
                        ? <Video videoSrcURL={videoSourceUrl} videoTitle={videoTitle}/>
                        : null
                }
            </Article>
        </Container>
    )
}

export default Story
